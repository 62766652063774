import React from 'react';
import './Header.scss';

export default class Header extends React.Component {
    render() {
        return <div id="header">
             <img src={process.env.PUBLIC_URL + '/images/background.jpg'} />
             <h2>Catherine Galais</h2>
             <h1>Je reconnais qui je suis</h1>
             <span>Accompagnement vers la reconnaissance de qui nous sommes vraiment</span>
        </div>
    }
}