import React from 'react';
import './Description.scss';

export default function Description({title, balises, priceText, price}) {
    balises.forEach((balise, i) => {
        balises[i] = {...balise, key: i};
    })

    return <div className="paragraph">
        <h3>{title}</h3>
        {balises.map(balise => (
            balise
        ))}
        {priceText ?
            <div>
                <p>Pour en savoir plus, et faire connaissance gratuitement, <span onClick={() => window.location.href='#Contact'}>me contacter</span>.</p>
                <div className="price-sentence">
                     <p>{priceText}</p>
                </div>
            </div>
        : null}
    </div>
}