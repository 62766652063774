import React from 'react';
import ImageContainer from '../Components/ImageContainer';
import Description from '../Components/Description';
import './Card.scss';


export default function Card({data}) {
    return <div id={data.tab ? data.tab : ''} className={data.reverse ? 'card reverse' : 'card'}>
        <ImageContainer image={data.image}/>
        <Description title={data.title} balises={data.balises} priceText={data.priceText} price={data.price}/>
    </div>
}