import React, { useState } from 'react';
import './ContactRow.scss';
import Button from './Button';

export default function ContactRow({contact}) {
    let {icon, link, button, text} = contact;
    const [clickLink, setClickLink] = useState(button);

    return <div className='contact-row'>
        <img src={process.env.PUBLIC_URL + '/icons/' + icon + '.png'} />
        {clickLink ?
            <Button onClick={() => setClickLink(false)} text="Afficher mon numéro" />
            :
            <a href={link} target="_blank">{text}</a>
        }
    </div>
}