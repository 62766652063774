import './SuccessMessage.scss';
import React, {useEffect, useState} from "react";

export default function SuccessMessage() {

    const [display, setDisplay] = useState(false);
    useEffect(() => {
        setDisplay(true);
        setTimeout(() => {
             setDisplay(false);
        }, 5000)
    }, []);

    return <div className={display ? "success-pop-up display" : "success-pop-up"}>
        <div className='icon'>
            <img src={process.env.PUBLIC_URL + '/icons/' + 'check.png'} />
        </div>
        <p>Votre message a été envoyé avec succès.</p>
    </div>
}