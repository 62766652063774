import React from 'react';
import './Navbar.scss';
import { useState, useEffect } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export default function Navbar({tabs}) {

  const { width } = useWindowDimensions();
  let responsive = width < 800;
  const [openMenu, setOpenMenu] = useState(false);
  let icon = openMenu ? 'close' : 'menu';

  return <nav className={getClassName(responsive, openMenu)}>
    <div className="navbar-banner">
      <p>Je reconnais qui je suis</p>
      {responsive ?
        <div className='icon' onClick={() => setOpenMenu(!openMenu)}>
          <img src={process.env.PUBLIC_URL + '/icons/' + icon + '.png'} />
        </div>
        : undefined
      }
    </div>

    <ul>
      {tabs.map((tab, i) => (
        <li key={i} onClick={() => {
          window.location.href='#' + tab;
          setOpenMenu(false);
        }}>{tab}</li>
      ))}
    </ul>
  </nav>
}

function getClassName(responsive, openMenu) {
  let className = 'navbar';
  if(!responsive) {
    return className;
  }
  className += ' navbar-column';
  if(openMenu) {
    return className + ' menu-open';
  } else {
    return className + ' menu-close';
  }
}