import React from 'react';
import ContactForm from '../Components/ContactForm';
import ContactRow from '../Components/ContactRow';
import './Contact.scss';

const PHONE_NUMBER = '+33 6 45 91 46 71';

const CONTACTS = [
    { icon: 'phone', button: 'Voir mon numéro', text: PHONE_NUMBER, link: 'tel:' + PHONE_NUMBER },
    { icon: 'map', text: 'Charavines, Isère (38)', link: 'https://www.google.com/maps?q=charavines&um=1&ie=UTF-8&sa=X&ved=2ahUKEwjs6r3rxOv2AhVLqxoKHdLWAJkQ_AUoAXoECAEQAw' }
]

export default function Contact({tab}) {
    return <div id={tab} className='contact'>
        <div className='contact-content'>
            <h3>Me contacter</h3>
            <div className="bloc">
                <div className='infos'>
                    {CONTACTS.map((contact, i) => (
                        <ContactRow key={i} contact={contact}/>
                    ))}
                </div>
                <ContactForm />
            </div>
         </div>
    </div>
}