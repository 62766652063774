import './App.scss';
import Header from './Panels/Header'
import Banner from './Panels/Banner';
import Card from './Panels/Card';
import Quotation from './Panels/Quotation';
import Contact from './Panels/Contact';
import Navbar from './Components/Navbar';

const TABS = {
  PRESENTATION: 'Présentation',
  PRESTATIONS: 'Prestations',
  ATELIERS: 'Ateliers',
  QUI_JE_SUIS: 'Qui je suis',
  CONTACT: 'Contact'
}

const BANNERS = [
  {
    banner: true,
    title: 'Présentation',
    tab: TABS.PRESENTATION,
    column: true,
    image: 'presentation',
    balises: [
      <p>Bienvenue à vous !</p>,
      <p>Le nom de ce site m’est venu tout naturellement suite à une voix, que j’ai entendue à l’intérieur de moi, et qui me disait : « Reconnais qui tu es ! ».</p>,
      <p>Ce site est donc une invitation à reconnaître notre véritable nature, qui nous sommes vraiment. Dans l’histoire de l’aigle et de la poule, un aigle était élevé dans un poulailler. Se croyant limité comme les poules, il se comportait comme elles. Puis un jour, il découvrit par hasard qu’il était un aigle, et qu’il pouvait voler. Et sa vie changea…</p>,
      <p>Pour nous, c’est la même chose. La proposition est de nous identifier à notre vraie nature, plutôt qu’à nos blessures, nos émotions, nos peurs, nos défauts, nos qualités, nos pensées limitantes, nos croyances erronées, etc…</p>,
      <p>Tout ceci définit notre petit moi, et nous fait croire que nous sommes limités. Nous sommes bien plus que cela. Notre vraie nature est, par essence, illimitée, joyeuse, aimante, rayonnante. Elle est en lien avec notre Présence, notre Conscience. Notre vraie nature est la même pour tous ; en cela, nous ne sommes pas différents les uns des autres.</p>,
      <p>La solution pour nous reconnecter à notre véritable nature, c’est de prendre du recul, de nous mettre dans la position de l’observateur, qui regarde les perturbations du petit moi. Il s’agit d’un repositionnement, d’une reconnaissance qu’à un certain niveau, nous sommes déjà accomplis.</p>,
      <p>Une des façons de se rapprocher de sa vraie nature est de recevoir des onctions, de manière corporelle et olfactive. Je vous propose également un accompagnement visant à reconnaître qui vous êtes vraiment.</p>
    ]
  },
  {
    title: 'Prestations en cabinet',
    tab: TABS.PRESTATIONS,
    reverse: true,
    image: 'cabinet',
    balises: [
      <b>VOYAGES OLFACTIFS ET SENSORIELS </b>,
      <p>Ces voyages odorants consistent en l’olfaction d’huiles pour onction, puis en leur application sur certaines parties du corps. Ces savants mélanges d’huiles végétales et d’huiles essentielles ont été élaborés minutieusement par un Créateur d’Onction ayant l’âme d’un Druide. Elles ont chacune leur particularité.</p>,
      <p>Après un échange verbal, vous serez allongé(e) confortablement, et je prendrai soin de vous pendant 1h ou 1h30, en fonction de la pratique appropriée, que je vous proposerai. Les bienfaits de ces voyages sont différents pour chacun(e), ils dépendent de notre degré d’ouverture, et de là où nous en sommes sur notre chemin d’évolution. Mais certainement, ils nous aident à libérer en douceur les émotions, qui sont prêtes à être libérées sur le moment, et à amplifier notre ouverture.</p>,
    ],
    priceText: 'de 40 à 80 €'
  },
  {
    title: 'Prestations à distance ou en cabinet',
    reverse: false,
    image: 'distance',
    balises: [
      <p>Le but de l’accompagnement que je vous propose est de vous donner des outils afin de vous permettre de vous accompagner vous-même, de vous prendre en charge, et ainsi de vous rendre autonome dans l’accueil de vous-même.</p>,
      <b>ÉCOUTE PONCTUELLE</b>,
      <p>Si vous traversez une période difficile, et avez besoin de déposer vos émotions, vos ressentis, je vous propose de vous écouter vraiment, avec bienveillance, et je vous accompagne afin de vous permettre de mieux vivre cette difficulté passagère (durée : 1h).</p>,
      <b>ACCOMPAGNEMENT VERS UN MIEUX-ÊTRE SUR PLUSIEURS SEMAINES</b>,
      <p>Dans le cas d’une problématique plus importante, ou récurrente, je vous propose un rendez-vous d’1h par semaine sur plusieurs semaines, ainsi que la possibilité d’échanger par mail en cas de besoin entre 2 rendez-vous, ceci afin de permettre un suivi personnalisé.</p>,
    ],
    priceText: '40 € l\'heure'
  },
  {
    quotation: "Nous sommes tous issus de la même Source, et nous sommes tous reliés au niveau de l’Esprit et de la Conscience."
  },
  {
    title: 'Ateliers de présence et de reconnaissance de qui nous sommes vraiment',
    tab: TABS.ATELIERS,
    reverse: true,
    image: 'ateliers',
    balises: [
      <p>Ces ateliers ont le but de transmettre des outils très simples permettant de reconnaître notre vraie nature, de s’en rapprocher, de la reconnecter. Ils peuvent se dérouler en présentiel ou à distance.</p>,
    ],
    priceText: 'Participation libre'
  },
  {
    banner: true,
    title: 'Qui je suis',
    tab: TABS.QUI_JE_SUIS,
    column: false,
    image: 'qui-je-suis',
    balises: [
      <p>Un être humain, qui a avancé sur son chemin d’évolution, grâce à des rencontres, des formations, des lectures, ainsi qu’à des expériences et compréhensions diverses.</p>,
      <p>Je souhaite maintenant mettre mon expérience au service de personnes, qui traversent des difficultés, des épreuves, ou qui se posent tout simplement des questions sur le sens de leur vie, et qui cherchent à être accompagnées. J’ai envie de donner, d’offrir mon temps et ma présence aux personnes qui se sentiront appelées à s’adresser à moi.</p>
    ]
  }
]

function App() {
  return (
    <div className="App">
      <Navbar tabs={Object.values(TABS)} />
      <Header/>
        <div className="content">
          <div className='background' style={{backgroundImage: 'url(' + process.env.PUBLIC_URL + '/images/background.jpg' + ')'}}></div>
          {BANNERS.map((data, i) => (
            data.quotation ?
            <Quotation key={i} quotation={data.quotation}/> :
            data.banner ?
            <Banner key={i} data={data} /> :
            <Card key={i} data={data} />
          ))}
          <Contact tab={TABS.CONTACT} />
      </div>
    </div>
  );
}

export default App;
