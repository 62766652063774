import React from 'react';
import ImageContainer from '../Components/ImageContainer';
import Description from '../Components/Description';
import './Banner.scss';


export default function Banner({data}) {
    return <div id={data.tab ? data.tab : ''} className={data.column ? 'banner column' : 'banner'}>
        <ImageContainer image={data.image}/>
        <Description title={data.title} balises={data.balises}/>
    </div>
}