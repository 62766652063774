import React, { useRef, useState } from 'react';
import './ContactForm.scss';
import Schema from 'validate'
import Button from './Button';
import SuccessMessage from "./SuccessMessage";

export default function ContactForm() {
    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState([]);
    const inputs = [
        { label: 'Nom', type: 'name', inputRef: useRef('') },
        { label: 'Adresse mail', type: 'email', inputRef: useRef('') },
        { label: 'Objet', type: 'object', inputRef: useRef('') },
        { label: 'Message', type: 'message', inputRef: useRef(''), textarea: true }
    ]

    const sender = new Schema({
        name: {
            type: String,
            required: true,
        },
        email: {
            type: String,
            required: true,
        },
        object: {
            type: String,
            required: true,
        },
        message: {
            type: String,
            required: true,
        },
    })

    const handleSubmit = async (event) => {
        const formValues = {
            name: inputs[0].inputRef.current.value,
            email: inputs[1].inputRef.current.value,
            object: inputs[2].inputRef.current.value,
            message: inputs[3].inputRef.current.value
        };

        const errors = sender.validate(formValues);

        let isEmailValid = checkEmail(formValues.email);
        if(!isEmailValid) {
            errors.push({
                message: 'email is not valid.'
            })
        }

        if (errors.length > 0) {
            setSuccess(false);
            event.preventDefault();
            const errorMessages = Object
                .values(errors)
                .map(function (fieldValues) {
                    return fieldValues.message;
                });

            setErrors(errorMessages);
            return;
        }
        try {
            const rawResponse = await fetch('/contact-form.php', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formValues)
            });
            const response = await rawResponse.text();
            if (response !== 'OK') {
                console.log('ERROR');
            } else {
                console.log('SUCCESS');
                setErrors([]);
                for(let input of inputs) {
                    input.inputRef.current.value = '';
                }
                setSuccess(true);
                setTimeout(() => {
                    setSuccess(false);
                }, 6000)
            }
        } catch (error) {
            console.log('ERROR');
        }
    }

    return <form id="contact-form">
        {inputs.map((input, i) => (
            <Input key={i} label={input.label} type={input.type} inputRef={input.inputRef} errors={errors} textarea={input.textarea} />
        ))}

        <div className="row">
            <Button onClick={handleSubmit} text="Envoyer" />
        </div>


        {success ? <SuccessMessage /> : undefined}
    </form>
}

function Input({ label, type, inputRef, errors, textarea }) {
    label += ' *';
    let errorMsg = '';
    for (let error of errors) {
        if (error.split(' ')[0] === type) {
            errorMsg = error;
        }
        if(errorMsg.includes('valid')) {
            errorMsg = capitalizeFirstLetter(type) + " n'est pas valide."
        } else if(errorMsg.includes('required')) {
            errorMsg = capitalizeFirstLetter(type) + " est requis."
        }
    }

    return <div className={errorMsg ? "field error" : "field"}>
        <label>{label}</label>
        {errorMsg ? <p className='error'>{errorMsg}</p> : undefined}
        {textarea ?
            <textarea ref={inputRef}></textarea> :
            <input ref={inputRef} type="text" />
        }
    </div>
}

function checkEmail(email) {
    let regex = new RegExp('(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])');

    return regex.test(email);
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}